<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Cars') }}</div>
            <AddNewButton :label="$t('Add')" @click="addNewItem" class="p-mr-2"/>
            <Button @click="removeDuplicates" class="p-button-secondary p-button-raised" icon="pi pi-refresh" label="Show duplicates"/>
          </template>
          <template #end>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>
        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else
                          :dataTableIsLoading="dataTableIsLoading"
                          :showHeader="false"
                          :tableName="$t('Cars')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
              {name: 'car_make', title: 'Make', sortable: false},
              {name: 'car_model', title: 'Model', sortable: false},
              {name: 'plate_number', title: 'Plate number', sortable: true},
               {name: 'orders_count', class: 'ti-hummer', width: '70px', sortable: false},
              {name: 'offers_count', class: 'ti-clipboard', width: '70px', sortable: false},
              {name: 'year', title: 'Year', sortable: true},
              {name: 'owner', title: 'Owner', sortable: false},
              {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'updated-item': isItemUpdated(item.id)}">
              <td>
                <span v-if="item.make">{{ item.make.name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.model">{{ item.model.name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.plate_number">{{ item.plate_number }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.ordersCount">{{  item.ordersCount }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.offersCount && +item.offersCount">{{  item.offersCount }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.year">{{ item.year }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <div v-if="item.owner" class="p-d-flex p-align-center">
                  <CustomerStatusHistoryButton
                      :customerStatus="item.owner.customerStatus"
                      :customerId="item.user_id"
                      @change-customer-status="changeCustomerStatus"/>
                  <router-link :to="{ path: `/customers/${item.owner.id}` }" target="_blank">
                    <span v-if="item.owner.type === constants.userTypes.legal_entity && item.owner.company_name">{{ item.owner.company_name }} </span>
                    <span v-else>{{ item.owner.first_name }} {{ item.owner.last_name }} </span>
                    <!--                  <span style="position: relative; top: -1px; left: 2px; transform: scale(0.45)" :class="'flag&#45;&#45;datatable flag flag-' + item.owner.flag_code"></span>-->
                  </router-link>
                </div>
                <i v-else class="ti-minus"></i>
              </td>
              <td class="table-date-line">
                <span v-if="item.created_at">
                  {{ formatDate(item.created_at) }},
                </span>
                <span v-if="item.creator">
                  {{ item.creator.first_name }} {{ item.creator.last_name }}
                </span>
              </td>
              <td>
                <div class="table__actions-column-data">
                  <router-link :to="{ path: `/cars/${item.id}` }"><ViewButton/></router-link>
                  <EditButton :disabled="(item.role === constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.superAdmin) || (disableEditButton && isItemUpdated(item.id)) || dataTableIsLoading"
                              @click="editItem(item)" />
                  <DeleteButton @click="confirmItemDelete(item)"
                                :disabled="dataTableIsLoading"
                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

<!--        &lt;!&ndash;  Mobile DataTable &ndash;&gt;-->
<!--        <MobileDataTable v-else-if="!isLoading && $store.state.tabletLayout"-->
<!--                         :tableName="$t('Taxes')"-->
<!--                         :showSearchInput="false"-->
<!--                         :itemsArrayIsEmpty="itemsArrayIsEmpty">-->
<!--          <template v-slot:body>-->
<!--            <tr :class="{'updated-item': isItemUpdated(item.id)}"-->
<!--                v-for="(item, index) of items"-->
<!--                :key="index">-->
<!--              <td>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center p-pt-2">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Name') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span>-->
<!--                      <div>{{ item.name  || '' }}</div>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Value:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">{{ item.value || '' }}</div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Is default:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span v-if="item.is_default">{{ $t('Yes') }}</span>-->
<!--                    <span v-else>{{ $t('No') }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-m-2 p-d-flex p-jc-center">-->
<!--                  <EditButton @click="editItem(item)" />-->
<!--                  <DeleteButton @click="confirmItemDelete(item)"-->
<!--                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </template>-->
<!--        </MobileDataTable>-->

        <OwnPaginator v-show="items && items.length"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

        <CarModal :visible="itemModal"
                  :item="item"
                  @update-items="updateItems"
                  @close="closeItemModal">
        </CarModal>

        <ConfirmDeleteModal :visible="confirmItemDeleteModal"
                            :modalDataIsLoading="confirmDeleteModalIsLoading"
                            @close="closeConfirmItemDeleteModal"
                            @delete-item="deleteItem"
                            :data="computedItemToDeleteData">
        </ConfirmDeleteModal>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import datatableMixins from "@/mixins/datatableMixins";
import CarModal from "@/pages/cars/components/CarModal";
import formatMixins from "@/mixins/formatMixins";
import CustomerStatusHistoryButton from "@/pages/users/components/CustomerStatusHistoryButton";
import settings from "@/settings";

export default {
  mixins: [ httpMixins, datatableMixins, formatMixins ],
  components: { CarModal, CustomerStatusHistoryButton },
	data() {
		return {
      fieldsExpand: 'creator,ordersCount,offersCount,owner,owner.customerStatus,ordersCount,offersCount',
      apiLink: 'car/get-active-cars',
      routePath: '/cars',
      sort: {
        column: 'id',
        direction: '-',
      },
		}
	},
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()

	},
  methods: {
    async removeDuplicates() {
      try {
        const { status, data } = await httpClient('car/remove-duplicates')
        console.log(data)
        if (status === 200) {
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: settings.toastLife})
        }
      } catch (err) {
        this.showError(err)
      }
    },
    changeCustomerStatus(newStatus, userId) {
      if (!userId) return false
      const itemsWithSameUser = this.items?.filter(i => {
        return i.user_id === userId
      })

      if (itemsWithSameUser?.length) {
        itemsWithSameUser.forEach(i => {
          if (i.owner) {
            if (i.owner.customerStatus) {
              i.owner.customerStatus.status_id = newStatus
            } else {
              i.owner.customerStatus = {}
              i.owner.customerStatus.status_id = newStatus
            }
          }
        })
      }
    },
    async getItems() {
      this.dataTableIsLoading = true
      this.disableEditButton = true
      try {
        const { status, data, headers } = await httpClient({
          // const { data } = await httpClient({
          url: this.apiLink,
          // method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            // search: this.searchProcessed ? this.searchData : null,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          // data: {}
        })

        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
      }
    },
    deleteItem() {
      const id = this.itemToDelete?.id
      if (!id) return
      this.processDeleteItem('car/delete', id)
    }
	},
  computed: {
    itemsArrayIsEmpty() {
        return !(this.items?.length)
    },
    actionButtonsCount() {
      if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin) {
        return 3
      } else {
        return 2
      }
    },
    computedItemToDeleteData() {
      if (!this.itemToDelete) return null
      let str = ''
      const makeName = this.itemToDelete.make?.name
      const modelName = this.itemToDelete.model?.name
      const plateNumber = this.itemToDelete.plate_number
      if (makeName) {
        str += makeName
        if (modelName) {
          str += ' '
        }
      }
      if (modelName) {
        str += modelName
      }
      if (plateNumber) {
        if (makeName || modelName) {
          str += ', '
        }
        str += plateNumber
      }
      return str
    },
  }
}
</script>

<style scoped lang="scss">

</style>